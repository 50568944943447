
.logoLoader {
  position:        fixed;
  z-index:         1000000;
  background:      black;
  left:            0;
  top:             0;
  width:           100%;
  height:          100%;
  display:         flex;
  align-items:     center;
  justify-content: center;
  img {
    width:    100px;
    position: relative;
    z-index:  1;
  }
}
