@import "../../../styles/variables";

.layout {
  min-height:      100vh;
  position:        relative;
  z-index:         1;
  display:         flex;
  flex-direction:  column;
  justify-content: space-between;
  overflow:        hidden;
  .logo {
    display:    block;
    text-align: center;
    padding:    52px 0 70px 0;
    img {
      width: 48px;
    }
  }
  .background {
    height:     100%;
    width:      100%;
    position:   fixed;
    z-index:    -1;
    top:        0;
    left:       0;
    object-fit: cover;
  }
  h1 {
    color:          white;
    letter-spacing: -4.4px;
    font-family:    'HK Grotesk', sans-serif;
    span {
      font-weight: 900;
      color:       $yellow;
    }
  }
}

@media screen and (max-width: 1024px) {
  .layout {
    .logo {
      padding: 30px 0 40px 0;
    }
    h1 {
      letter-spacing: -2.2px;
      font-size:      clamp(32px, 11vw, 50px);
    }
  }
}